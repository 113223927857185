

let academy = ""

export function saveAcademy(type: string) {
    academy = type;
}

export function loadAcademy() {
    return academy;
}

let isMaleS = JSON.parse(sessionStorage.getItem("isMale") || "false");
export function selectUserType(isMale: boolean) {
    isMaleS = isMale
    sessionStorage.setItem("isMale", isMale ? "true" : "false");
}

export function getUserType() {
    return isMaleS;
}


const openId = sessionStorage.getItem("openId");
export function getOpenId() {
    return openId;
}

const userName = sessionStorage.getItem("nickname") || "";

export function getUserName() {
    return userName;
}

export async function loadImg(source: Promise<typeof import("*.png")>, img: HTMLImageElement = new Image()) {
    return new Promise<HTMLImageElement>(async (resolve) => {
        img.onload = () => {
            resolve(img);
        }

        img.src = (await source).default;
    })
}
import { useState, useEffect, useRef } from 'react';
import './App.css';

import { useSpring, animated, config } from 'react-spring'
import Home from "./pages/home/home";
import History from "./pages/history/index";
import Selectuser from "./pages/selectUser/index";
import Welcome from "./pages/welcome/index";
import Academy from "./pages/academy/index";
import AcademyInfo from "./pages/academy-info/index";
import AcademyNext from "./pages/academy-next/index";
import Topic from "./pages/topic/index";
import Map from "./pages/map/index";
import Selecteduser from "./pages/selectedUser/index";
import Card from "./pages/card/index";

const bg = require("./images/bg.mp3");

function Wrapped(WrappedComponent: any, baseProps: any) {
  return (args: any) => {
    return <WrappedComponent {...baseProps}  {...args} />
  }
}

const bglist = !sessionStorage.getItem("nickname") ? [
  {
    bgColor: "#E7F0F9",
    el: Home
  },
  {
    bgColor: "#FFF",
    el: History
  },
  {
    bgColor: "#FDEFE2",
    el: Selectuser
  }] : [
  {
    bgColor: "#4D669B",
    el: Welcome
  },
  {
    bgColor: "#FFF",
    el: Map
  },
  {
    bgColor: "#FFF",
    el: Academy
  },
  {
    bgColor: "#FFF",
    el: Topic
  },
  {
    bgColor: "#FFF",
    el: Wrapped(AcademyNext, { isNext: false })
  },
  {
    bgColor: "#FFF",
    el: Wrapped(AcademyNext, { isNext: true })
  },
  {
    bgColor: "#E7F0F9",
    el: AcademyInfo
  },
  {
    bgColor: "#E7F0F9",
    el: Card
  },
];

function App() {
  useEffect(() => {
    const preventDefault = (e: Event) => e.preventDefault()
    document.addEventListener('gesturestart', preventDefault)
    document.addEventListener('gesturechange', preventDefault)

    return () => {
      document.removeEventListener('gesturestart', preventDefault)
      document.removeEventListener('gesturechange', preventDefault)
    }
  }, [])

  const [index, changeIndex] = useState(0);

  const audioRef = useRef<HTMLAudioElement>(null);

  const Page = bglist[index];

  const { bgColor } = useSpring({ bgColor: Page.bgColor, immediate: true });

  useEffect(() => {
    if (audioRef.current)
      audioRef.current.oncanplay = function () {
        console.log("canplay");
        play();
      }
  }, [])

  return <div onPointerDown={play}>
    <animated.div className="bg" style={{ backgroundColor: bgColor }} />
    <Page.el next={next} />
    {sessionStorage.getItem("nickname") && <audio src={bg} autoPlay loop ref={audioRef} />}
  </div>

  async function next(animated: any) {
    changeIndex(index + 1);
  }

  function play() {
    if (audioRef.current && audioRef.current.paused) {
      audioRef.current.play();
    }
  }
}

export default App;

import styles from './styles.module.css'
import { useSpring, useSprings, animated } from 'react-spring'
import { useEffect, useState } from 'react';
import { useNextGesture } from '../../utils/useNextGesture';
import { getUserType, loadAcademy } from '../../utils/store';
import { useGesture } from '@use-gesture/react';

export default function Index({ next }: any) {
    const text = loadAcademy();

    let bg = "";

    const isMale = getUserType();

    const [{ opacity, scale }, api] = useSpring(() => ({ opacity: 0, scale: 1 }));
    const [isPhoto, changePhoto] = useState(false);

    switch (text) {
        case "α":
            bg = styles.a1bg;
            break;
        case "β":
            bg = styles.b1bg;
            break;
        case "γ":
            bg = styles.y1bg;
            break;
        case "δ":
            bg = styles.g1bg;
            break;
    }

    const bind = useGesture({
        onPointerDown() {
            changePhoto(true);
            api({ opacity: 1 });

            setTimeout(() => {
                api({
                    opacity: 0.8, scale: 0.8,
                    onRest(result, ctrl, item?) {
                        next()
                    },
                });
            }, 500);
        }
    })

    return (
        <animated.div className={`${styles.page} ${bg}`} style={{ scale }}>
            <animated.div className={`${styles.user} ${isMale ? styles.male : ""}`}></animated.div>
            <animated.div className={styles.button} {...bind()}>领取我的学生证</animated.div>
            {isPhoto && <animated.div className={styles.photo} style={{ opacity }}></animated.div>}
        </animated.div>
    );
}
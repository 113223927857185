import styles from './styles.module.css'
import { useSpring, useSprings, animated, config } from 'react-spring'
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useNextGesture } from '../../utils/useNextGesture';
import { loadAcademy } from '../../utils/store';
import { useGesture } from '@use-gesture/react';


export default function Index({ next, isNext }: any) {
    const text = loadAcademy();

    let bg = "";

    switch (text) {
        case "α":
            bg = isNext ? styles.a1bgn : styles.a1bg;
            break;
        case "β":
            bg = isNext ? styles.b1bgn : styles.b1bg;
            break;
        case "γ":
            bg = isNext ? styles.y1bgn : styles.y1bg;
            break;
        case "δ":
            bg = isNext ? styles.g1bgn : styles.g1bg;
            break;
    }

    const [isDialog, checkDialog] = useState(true);
    const [button, showButton] = useState(false);
    const pos = useRef({ startX: 0, maxX: 0 });
    const page = useRef<HTMLDivElement>(null);
    const background = useRef<HTMLDivElement>(null);

    const [{ x }, api] = useSpring(() => ({ x: 0 }));

    const bindMove = useGesture({
        onDrag: ({ down, movement: [mx], velocity: [vx] }) => {
            let mv = pos.current.startX + mx * (down ? 1 : 1 + vx);
            let cfg: any = null;

            if (isDialog) {
                checkDialog(false);
                setTimeout(() => showButton(true), 1000)
            }

            if (mv > 0) { mv = 0; }
            if (mv < -pos.current.maxX) { mv = -pos.current.maxX; }

            if (down) {
                api.start({ x: mv, immediate: true });
            } else {
                api.start({ x: mv, config: cfg });
                pos.current.startX = mv;
            }
        }
    })

    useLayoutEffect(() => {
        if (background.current && page.current) {
            let maxWidth = background.current.offsetWidth;
            let windowWidth = page.current.offsetWidth;

            let centerX = (maxWidth - windowWidth) / 2;

            api.start({ x: -centerX });
            pos.current.startX = -centerX;
            pos.current.maxX = maxWidth - windowWidth;
        }
    }, [])

    const bind = useGesture({
        onClick() {
            next();
        }
    });

    return (
        <div className={`${styles.page}`} {...bindMove()} ref={page}>
            <animated.div className={bg} style={{ x }} ref={background}></animated.div>
            {isDialog && !isNext && <animated.div className={styles.bg}>
                <animated.div className={styles.academyInfo} >{text}书院内景</animated.div>
                <animated.div className={styles.info}>左右滑动查看</animated.div>
            </animated.div>}
            {button && <animated.div className={styles.button} {...bind()}>{isNext ? `欢迎入读${text}书院` : "换个地方看看吧"} </animated.div>}
        </div>
    );
}
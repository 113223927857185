import styles from './styles.module.css'
import { useSpring, useSprings, animated } from 'react-spring'
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useGesture } from '@use-gesture/react';
import { loadImg } from '../../utils/store';

const maxWidth = 1000;
const maxHeight = 1000;

const pointerLength = [
    200,
    465,
    815,
    920.1189575195312,
    1050.5030517578125,
    1097.6729736328125,
    1268.33349609375,
    1422.3626708984375,
    1508.385986328125,
    1913.385986328125,
    2363.413818359375
];

const maxLength = pointerLength[pointerLength.length - 1];

const pointers = [
    "L750 1300",
    "L485 1300",
    "L485 950",
    "L480 845",
    "L610 835",
    "L650 860",
    "L820 875",
    "L970 840",
    "L920 770",
    "L1325 770",
    "L1330 320"
]

const stopPointIndexs = [3, 7, 8, 9, 11];


const info = [
    {
        isEnd: 0,
        title: "生命科学学院",
        content: "致力于研究生命现象和疾病机理以及研发生物医药新技术和疾病诊治新方法，培养具有生物、医学、化学、生物医学工程、药学等多学科背景的顶尖复合型创新人才，建立世界一流的生物医学研究和人才培养基地。"
    },
    {
        isEnd: 0,
        title: "理学院",
        content: "以“发现物质世界现象、阐述自然运行规律、拓展人类知识边界、培养一流人才”为使命，设有数学、物理、化学三大学科，研究领域方向广泛，致力于前瞻性和系统性的基础科学研究，培养具有较强创新能力的杰出人才，建设国际一流的创新研究平台。"
    },
    {
        isEnd: 0,
        title: "工学院",
        content: "致力于面向国家战略性新型产业发展重大需求的科技创新和人才培养，建立交叉学科与新兴学科为特色的应用科学、工程技术学科群，建成国家重大科学技术研究和拔尖创新人才培养的重要基地。"
    },
    {
        isEnd: 0,
        title: "学术会堂",
        content: "西湖大学举办大型国际会议、学术活动、开学典礼及文艺演出的地方。未来，这里将是杭州的城市文化地标，世界级大师往来穿梭，传播最前沿的观点，进行最激烈的思想碰撞，奉献最经典的艺术表演。"
    },
    {
        isEnd: 1
    }
]

const bgImg = import("../../images/new/map_1.png");
const wordImg = import("../../images/new/map_2.png");

export default function Index({ next }: any) {
    const domTarget = useRef<any>(null);
    const mapRef = useRef<HTMLDivElement>(null);
    const origin = useRef<{ x: number, y: number, stop: boolean, isStart: boolean, start?: () => void }>({ x: 0, y: 0, stop: false, isStart: false })
    const [display, displayInfo] = useState<any>(null);
    const [isFirst, changeFirst] = useState<any>(true);
    const [button, showButton] = useState(false);

    const [{ x: mapX, y: mapY, ox, oy, scale, scaleX, opacity }, api] = useSpring(() => ({ x: 0, y: 0, ox: 0, oy: 0, scale: 1, scaleX: 1, rotateZ: 0, opacity: 0 }))

    useGesture({
        onDrag: ({ down, pinching, cancel, movement: [mx, my], offset: [ox, oy], velocity: [_, velocity] }) => {
            if (origin.current.start) {
                const { x, y, start } = origin.current;

                origin.current.stop = down;
                api.start({ x: down ? x + mx : x, y: down ? y + my : y, immediate: down })


                if (display && display.isEnd) {
                    next();
                }

                if (!down && start) {
                    start();
                }
            } else {
                if (pinching) return cancel()
                api.start({ x: ox, y: oy })
            }
        },
        onPinch: ({ origin: [ox, oy], first, movement: [ms], offset: [s, a], memo }) => {
            if (origin.current.start) return;

            if (first && mapRef.current) {
                const { width, height, x, y } = mapRef.current.getBoundingClientRect()
                const tx = ox - (x + width / 2)
                const ty = oy - (y + height / 2)
                memo = [mapX.get(), mapY.get(), tx, ty]
                changeFirst(false);

                setTimeout(() => {
                    if (!origin.current.start) showButton(true)
                }, 1000)
            }

            const x = memo[0] - (ms - 1) * memo[2]
            const y = memo[1] - (ms - 1) * memo[3]
            api.start({ scale: s, rotateZ: a, x, y })
            return memo
        },
        onPointerDown: () => {

        }
    }, {
        target: mapRef,
        drag: { from: () => [mapX.get(), mapY.get()] },
        pinch: { scaleBounds: { min: 0.4, max: 1.5 }, rubberband: true },
    })

    const bind = useGesture({
        onClick() {
            showButton(false);
            startAnimation();
        }
    });

    const nextG = useGesture({
        onClick() {
            if (origin.current.stop && origin.current.start) {
                if (display.isEnd) {
                    next();
                } else {
                    origin.current.start();
                }
            }
        }
    });

    const definePath = useRef<SVGPathElement>(null);
    const pathEL = useRef<SVGPathElement>(null);
    const backgroundRef = useRef<HTMLImageElement>(null);
    const wordRef = useRef<HTMLImageElement>(null);

    useLayoutEffect(() => {
        if (origin.current.isStart) return;
        origin.current.isStart = true;

        let { offsetWidth, offsetHeight } = domTarget.current;

        if (backgroundRef.current && wordRef.current) {
            let scale = offsetWidth / 1000;
            let startX = - (maxWidth - offsetWidth) / 2;
            let startY = - (maxHeight - offsetHeight) / 2;

            api.start({ x: startX, y: startY, scale, immediate: true });

            Promise.all([loadImg(bgImg, backgroundRef.current),
            loadImg(wordImg, wordRef.current),
            loadImg(import("../../images/acadmy/a-show.png"), new Image())]).then(async () => {
                api.start({ x: startX, y: startY, scale, immediate: true });
            });
        }
    }, [])

    type Args1<T> = T extends (args1: infer T1, ...args: any[]) => any ? T1 : never;

    function nextAction(props: Args1<typeof api.start>, duration: number) {
        return new Promise((resolve) => {
            api.start({
                ...props, config: { duration },
                onRest: resolve
            });
        })
    }

    return (
        <div className={styles.page} {...nextG()} ref={domTarget}>
            {display && (display.isEnd ? <div className={styles.bg}>
                <div className={styles.message}>欢迎来到本科生书院，让我来带你参观一下</div>
                <div className={styles.endinfo}></div>
            </div> : <div className={styles.bg}>
                <div className={styles.academyInfo}>
                    <div className={styles.title}>{display.title}</div>
                    <p>
                        {display.content}
                    </p>
                </div>
            </div>)}
            {isFirst && <div className={styles.iwenzi} />}
            <animated.div className={styles.map} style={{ x: mapX, y: mapY, scale }} ref={mapRef}>
                <img className={styles.image} ref={backgroundRef} />
                <svg style={{ position: "absolute", left: 0, top: 0, width: "100%", height: "100%" }} viewBox="0 0 1584 1584">
                    <path ref={definePath} d="M750 1500, L750 1300, L485 1300, L485 950, L480 845, L610 835, L650 860, L820 875, L970 840, L920 770, L1325 770, L1330 320" fill='none' />
                    <path ref={pathEL} fill='none' stroke="#79502C" strokeDasharray="10 8" strokeWidth="6" />
                </svg>
                <animated.div className={styles.moveu} style={{ x: ox, y: oy, scaleX, opacity }}>
                </animated.div>
                <img className={styles.image} ref={wordRef} />
            </animated.div>
            {button && <animated.div className={styles.button} {...bind()}>从大门出发吧</animated.div>}
        </div>
    );

    function startAnimation() {
        let centerX = domTarget.current.offsetWidth / 2, centerY = domTarget.current.offsetHeight - 300;

        let step = 0;
        let stoped = 0;
        let stopPointIndex = stopPointIndexs[0];
        let prewDate = +new Date();
        let maxTime = 15 * 1000;
        let exTime = 0;
        let prewX = 0;
        let prewUp = 1;
        let ps = 0.6313131313131313;

        move(750, 1500);

        origin.current.start = () => {
            prewDate = +new Date();
            origin.current.stop = false;
            displayInfo(null);
            startAnimation();
        };

        startAnimation();

        function nextStop() {
            origin.current.stop = true;
            stopPointIndex = stopPointIndexs[stoped]


            const dinfo = info.shift();
            displayInfo(dinfo);
        }

        function move(x: number, y: number, duration?: number) {
            const ox = (centerX - x * ps);
            const oy = (centerY - y * ps);

            origin.current.x = ox;
            origin.current.y = oy;

            if (prewX != ox)
                prewUp = prewX > ox ? 1 : -1;

            api.start({ x: ox, y: oy, scale: 1, ox: x * ps, oy: y * ps, scaleX: prewUp, opacity: 1, immediate: (key) => key == "ox" || key == "oy" || key == "scaleX" || key == "opacity", config: { duration } });
            prewX = ox;
        }

        function startAnimation() {
            requestAnimationFrame((id) => {
                if (origin.current.stop) {
                    return;
                }

                let now = (+new Date());
                let nowTime = exTime + now - prewDate;
                let pos = nowTime / maxTime;

                prewDate = now;
                exTime = nowTime;

                if (nowTime / maxTime >= 1) {
                    cancelAnimationFrame(id);
                    nextStop();
                    return;
                }

                const current = maxLength * (pos);


                const stepLength = pointerLength[step];

                if (current > stepLength) {
                    step++;

                    if (step == stopPointIndex) {
                        stoped++;
                        cancelAnimationFrame(id);
                        nextStop();
                        return;
                    }
                }

                const { x, y } = definePath.current?.getPointAtLength(current) || { x: 0, y: 0 };

                let path = ["M750 1500", ...pointers.slice(0, step), `L${x} ${y}`].join(",");
                pathEL.current?.setAttribute("d", path);

                move(x, y)
                startAnimation();
            })
        }
    }
}
import styles from './styles.module.css'
import { useSpring, animated } from 'react-spring'
import { useEffect, useRef, useState } from 'react';
import { useNextGesture } from '../../utils/useNextGesture';
import { useGesture } from '@use-gesture/react';
import { saveAcademy } from '../../utils/store';


const answers: [string, string[], number[]][] = [
    [
        "人，在柏油路、沙地、森林小道上，想怎么走就怎么走。那机器人到了崎岖的森林小道里，它会怎么走路？",
        ["跳着走", "弯腰走", "飞过去", "躺平不走"],
        [0, 1, 2, 3]
    ],
    [
        "一份科研成果报告和一幅画同时烧起来，你先救哪个？",
        ["科研报告", "画", "都不救", "为什么会起火？"],
        [1, 2, 3, 0]
    ],
    [
        "科学研究有时候就像破案，面对看不见摸不着的对手，需要大胆假设，小心求证，外加细致入微的观察。你最像哪个侦探？",
        ['福尔摩斯', '包青天', '李昌钰', '江户川柯南'],
        [2, 3, 0, 1]
    ],
    [
        "你是不是经常觉得，天气预报好像有时候不太“灵”？谁能帮助改变这个现实？",
        ['人工智能（AI）', '神经网络', '科学家', '气象水文'],
        [2, 3, 0, 1]
    ],
    [
        "实验室里的研究对象最“受宠”的是谁？",
        ['链霉菌', '小鼠大脑', '纳米光栅', '金鸡纳碱'],
        [2, 0, 3, 1]
    ],
    [
        "AI能读懂我的悲伤吗？",
        ['为什么不能', '能解悲伤', '能哄高兴', '能陪成长'],
        [2, 0, 1, 3]
    ],
    [
        "这些生活中的常见物质，属于有机高分子材料的是谁？",
        ['挡风玻璃', '水泥', '食品保鲜膜', '塑料袋'],
        [3, 2, 0, 1]
    ]
];

const NoAction = () => { };

function randBetween(min: number, max: number) {
    return Math.floor(Math.random() * max) + min;
}
const colours = ["#F7B549", "#6DCFE6", "#F44274", "#B3DF85", "#B5178C"];
// const colours = ["#F73859", "#14FFEC", "#00E0FF", "#FF99FE", "#FAF15D"];

class Ball {
    angle: number;
    multiplier: number;

    vx: number
    vy: number;

    r: number;
    color: string;

    direction: number;

    constructor(public x: number, public y: number, public nx: number, public ny: number, public maxWidth: number, public maxHeight: number) {
        this.angle = Math.PI * 2 * Math.random();

        this.multiplier = randBetween(6, 12);

        this.vx = (this.multiplier + Math.random() * 0.5) * Math.cos(this.angle);
        this.vy = (this.multiplier + Math.random() * 0.5) * Math.sin(this.angle);
        this.r = randBetween(8, 10) + 3 * Math.random();
        this.color = colours[Math.floor(Math.random() * colours.length)];
        this.direction = randBetween(-1, 1);
    }

    update() {
        this.x += this.vx - this.nx;
        this.y += this.vy - this.ny;

        this.nx = -2 / this.maxWidth * Math.sin(this.angle);
        this.ny = -2 / this.maxHeight * Math.cos(this.angle);

        this.r -= 0.3;
        this.vx *= 0.9;
        this.vy *= 0.9;
    }
}



export default function Index({ next }: any) {
    const [text, changeSelect] = useState("");

    const [delta, changeDelta] = useState(0);

    const [isEnd, domTarget] = useNextGesture(changeDelta, text !== "");

    const imagePlay = useRef<{ start: Function }>({ start: NoAction });

    const image = useRef<HTMLCanvasElement>(null);

    const { y, opacity } = useSpring({
        y: delta,
        opacity: isEnd ? 0 : 1,
        config: { mass: 1, tension: 350, friction: 40 },
        onRest: () => isEnd && next()
    })

    const bind = useGesture({
        onPointerDown: ({ args: [isOne] }) => select(isOne)
    })

    function select(current: number) {
        let text = "";

        switch (current) {
            case 0:
                text = "α";
                break;
            case 1:
                text = "β";
                break;
            case 2:
                text = "γ";
                break;
            case 3:
                text = "δ";
                break;
        }

        saveAcademy(text);
        changeSelect(text);
        imagePlay.current.start();
    }

    let p = answers[Math.floor(Math.random() * 8)]

    useEffect(() => {
        if (image.current) {
            const canvas = image.current;
            imagePlay.current.start = buildPlay(canvas)
        }
    }, []);

    function buildPlay(canvas: HTMLCanvasElement) {
        let { offsetHeight: maxHeight, offsetWidth: maxWidth } = canvas
        let ctx = canvas.getContext("2d") as CanvasRenderingContext2D;

        canvas.width = maxWidth;
        canvas.height = maxHeight

        let balls: Ball[] = [];

        for (let i = 0; i < 100; i++) {
            balls.push(new Ball(maxWidth, maxHeight, maxWidth / 2, maxHeight / 2, maxWidth, maxHeight));
        }

        return animation;

        function animation() {
            ctx.clearRect(0, 0, maxWidth, maxHeight);

            for (let i = 0; i < balls.length; i++) {
                let b = balls[i];

                if (b.r < 0) continue;

                ctx.fillStyle = b.color;
                ctx.beginPath();
                ctx.arc(b.x, b.y, b.r, 0, Math.PI * 2, false);
                ctx.fill();

                b.update();
            }

            removeBall();

            if (balls.length > 0)
                requestAnimationFrame(animation)
        }

        function removeBall() {
            for (let i = 0; i < balls.length; i++) {
                let b = balls[i];
                if (
                    b.x + b.r < 0 ||
                    b.x - b.r > maxWidth ||
                    b.y + b.r < 0 ||
                    b.y - b.r > maxHeight ||
                    b.r < 0
                ) {
                    balls.splice(i, 1);
                }
            }
        }

    }

    return (
        <div className={styles.page} ref={domTarget}>
            <canvas className={styles.showImage} ref={image} />
            <div className={styles.page_c}>
                {!text ? <>
                    <div className={styles.title}>答题分书院啦！</div>
                    <div className={styles.question}>{p[0]}</div>
                    <div className={styles.answers}>
                        {p[1].map((c, i) => <div key={i} className={styles.answer} {...bind(p[2][i])}>
                            {String.fromCharCode(65 + i)}:{c}
                        </div>)}
                    </div>
                </> : <>
                    <animated.div className={styles.welcome} style={{ y, opacity }}>
                        欢迎入读{text}书院
                    </animated.div>
                    <animated.div className={styles.iicon} style={{ y, opacity }}></animated.div>
                </>}
            </div>
        </div>
    );
}
import styles from './styles.module.css'
import { useSpring, useSprings, animated } from 'react-spring'
import React, { useEffect, useRef, useState } from 'react';
import { useGesture } from '@use-gesture/react';
import { getUserName, getUserType, loadAcademy } from '../../utils/store';

export type Format = {
    text: string;
    fontFamily: string
    fontSize: number
    font: string;
    lineHeight: number;
    color: string
    underline?: boolean;
    italic?: boolean;
    bold?: boolean;
};

function buildTable(datas: Format[][][], measureTextWith: (text: Format) => number) {
    let rows = datas.map(_ => 0);
    let cols = datas[0].map(_ => 0);

    for (var j = 0; j < datas.length; j++) {
        let tr = datas[j];
        let maxHeight = 0;

        for (var i = 0; i < tr.length; i++) {
            let item = tr[i];
            let maxWidth = cols[i];
            let height = 0;

            for (var line of item) {
                let textWidth = measureTextWith(line);

                if (maxWidth < textWidth)
                    maxWidth = textWidth;

                height += line.lineHeight;
            }


            if (maxHeight < height) {
                maxHeight = height;
            }

            cols[i] = maxWidth;
        }

        rows[j] = maxHeight
    }

    return {
        rows,
        cols
    }
}

function T(text: string, color: string, fontSize: number, fontFamily = "UseFont"): Format {
    return {
        text,
        fontSize,
        color,
        lineHeight: fontSize * 1.6,
        fontFamily: fontFamily,
        font: `${fontSize}px ${fontFamily}`,
    }
}

const FT = (text: string) => T(text, "#1C4682", 24);
const ST = (text: string) => T(text, "#1C4682", 24);
const LT = (text: string, fontFamily = "UseFont") => T(text, "#333", 32, fontFamily);

function buildLine(title: string, subTitle: string, content: string) {
    return [[FT(title), ST(subTitle)], [LT(content)]];
}

async function drawTable(ctx: CanvasRenderingContext2D, table: Format[][][], ox: number, oy: number) {
    const format = buildTable(table, (obj) => {
        if (ctx !== null) {
            ctx.font = `${obj.fontSize}px ${obj.fontFamily}`;
            ctx.textBaseline = "top";
            // console.log(obj.text, ctx.measureText(obj.text).width);

            return ctx.measureText(obj.text).width;
        }

        return 0;
    })

    let totalHeight = 0;

    table.forEach((row, i) => {
        totalHeight += format.rows[i];

        row.forEach((g, j) => {
            let colWidth = j == 0 ? 0 : format.cols[j - 1]
            let y = totalHeight;

            for (let p of g) {
                ctx.font = p.font;
                ctx.fillStyle = p.color;
                ctx.textBaseline = "top";
                ctx.fillText(p.text, ox + colWidth, oy + y);

                ctx.beginPath()
                ctx.moveTo(ox + colWidth, oy + y)
                ctx.lineTo(ox + colWidth + 100, oy + y)

                y += p.lineHeight
            }
        })
    })
}

function drawText(ctx: CanvasRenderingContext2D, text: Format, x: number, y: number) {
    ctx.font = text.font;
    ctx.fillStyle = text.color;
    ctx.textBaseline = "top";
    ctx.fillText(text.text, x, y);
}

function createDrawLine(ctx: CanvasRenderingContext2D, startX: number, startY: number) {
    let y = startY;

    return {
        drawLine(text: Format, marginTop = 0) {
            y += marginTop;
            drawText(ctx, text, startX, y);

            y += text.lineHeight;
        }
    }
}

interface DrawInfo {
    width: number;
    height: number;
    isMale: boolean;
    academy: string;
}

interface Position {
    left?: number;
    top?: number;
    right?: number;
    bottom?: number;

    widthScale?: number;
    heightScale?: number;
}

async function drawCover(canvas: HTMLCanvasElement, info: DrawInfo) {
    let ctx = canvas.getContext("2d");
    if (ctx) {
        ctx.fillStyle = "#E7F0F9";
        ctx.fillRect(0, 0, info.width, info.height);

        let bgImgP: Promise<typeof import("*.png")>
        let position: Position;
        let key: string;
        let scale = 0.7552870090634441;

        switch (info.academy) {
            case "α":
                bgImgP = import("../../images/new/A.png");
                position = { left: 30, bottom: 30 };
                key = "智慧力"
                break;
            case "β":
                bgImgP = import("../../images/new/B.png");
                position = { left: 30, bottom: 30 };
                key = "敏捷度"
                break;
            case "γ":
                bgImgP = import("../../images/new/C.png");
                position = { right: 30, bottom: 30 };
                key = "活力值"
                break;
            default:
                bgImgP = import("../../images/new/D.png");
                position = { left: 30, bottom: 30 };
                key = "自由性"
                break;
        }


        let [
            _font,
            { default: bg },
            { default: card },
            { default: logo },
            { default: user }] = await Promise.all([
                document.fonts.load("12px UseFont"),
                bgImgP,
                import("../../images/new/card-bg.png"),
                import("../../images/new/renzheng.png"),
                info.isMale ? import("../../images/selectUser/xiaoxi2.png") : import("../../images/selectUser/xiaoxi1.png")
            ]);

        await drawImages(ctx, bg, info, { left: 0, bottom: 0, widthScale: scale, heightScale: scale });

        await drawImages(ctx, card, info, { right: 9, top: 300, widthScale: scale, heightScale: scale });

        await drawImages(ctx, user, info, { left: 120, top: 420, widthScale: 0.45, heightScale: 0.45 });

        await drawImages(ctx, logo, info, { left: 200, top: 650, widthScale: 0.75, heightScale: 0.75 });


        var sy = 390,
            sx = 480,
            builder = createDrawLine(ctx, sx, sy);

        builder.drawLine(LT(info.academy + "书院"), 5)

        builder.drawLine(LT(getUserName(), "PingFang"), 28)

        builder.drawLine(LT(info.isMale ? "男" : "女"), 28)

        builder.drawLine(LT("2022年7月"), 28)
    }
}

function drawImages(ctx: CanvasRenderingContext2D, src: string, info: DrawInfo, position: Position) {
    return new Promise(function (resolve, reject) {
        let image = new Image();

        image.onload = function () {
            let x = position.left,
                y = position.top;

            let width = image.width * (position.widthScale || 1),
                height = image.height * (position.heightScale || 1);

            if (position.top === undefined && position.bottom !== undefined) {
                y = info.height - height - position.bottom;
            }
            if (position.left === undefined && position.right !== undefined) {
                x = info.width - width - position.right;
            }

            ctx.drawImage(image, x || 0, y || 0, width, height);

            resolve(image);
        }

        image.onerror = reject;

        image.src = src;
    })

}

export default function Index({ next }: any) {
    const cavansRef = useRef<{ canvas: HTMLCanvasElement | null }>({ canvas: null })
    const imageRef = useRef<HTMLImageElement>(null)

    const isMale = getUserType();
    const academy = loadAcademy();

    const [isDialog, checkDialog] = useState(false);

    useEffect(() => {
        if (!cavansRef.current.canvas) {
            let canvas = document.createElement("canvas");
            let width = 750, height = 1190;

            canvas.width = width;
            canvas.height = height;

            cavansRef.current.canvas = canvas;

            let image = imageRef.current as HTMLImageElement;
            let { offsetWidth, offsetHeight } = image.parentElement?.parentElement as HTMLDivElement;
            let sw = offsetWidth / width, sh = offsetHeight / height;

            let scale = sw > sh ? sh : sw;

            image.width = width * scale;
            image.height = height * scale;

            drawCover(canvas, {
                width, height, isMale, academy
            }).then(() => {
                image.src = canvas.toDataURL("image/jpeg");
            })
        }
    }, []);

    return <div className={styles.page}>
        {isDialog && <div className={styles.bg} onPointerDown={() => checkDialog(false)}>
            长按保存分享图片
        </div>}
        <div className={styles.ubox}>
            <div className={styles.img}>
                <img ref={imageRef} />
            </div>
        </div>
        <div className={styles.btns}>
            <div className={styles.btn + " " + styles.share} onClick={() => checkDialog(true)}>
            </div>
            <div className={styles.btn + " " + styles.addBtn} onClick={() => toLink()}>
            </div>
        </div>
    </div>

    function toLink() {
        window.location.href = "https://donate.bangbangwang.cn/p/ca4x83g5ep9wn0rr4ox450mlo1qyzv27?partner_id=ca7nxo5qvzl9gm10qlok67w3rp24e8yk&broadcast_channel_id=can9v23wqo8z7mdnw89exdkl5eg4py1r&ouid=can47pzywmr8gd1gkg9q8d1vkxlq2o95";
    }
}
import styles from './styles.module.css'
import { useSprings, animated } from 'react-spring'
import { useNextGesture } from '../../utils/useNextGesture';
import { useEffect, useState } from 'react';

const classNames = [
    styles.iyanjing,
    styles.i2016,
    styles.ixian1,
    styles.i2017,
    styles.ixian2,
    styles.i2018,
    styles.ixian3,
    styles.i2019,
    styles.ixian4,
    styles.i2022,
    styles.ixiahuatishi,
    styles.iicon,
]

const froms = [
    { x: 0, y: 0 },
    { x: -10, y: -20, scale: 0.8 },
    { x: -10, y: -20, scale: 0.5 },
    { x: -10, y: -20, scale: 0.8 },
    { x: 10, y: -20, scale: 0.5 },
    { x: 10, y: 0, scale: 0.8 },
    { x: -10, y: -20, scale: 0.5 },
    { x: -20, y: -10, scale: 0.8 },
    { x: 20, y: -20, scale: 0.5 },
    { x: 20, y: -10, scale: 0.8 },
    { x: 0, y: -20 },
    { x: 0, y: -20 },
]

export default function Home({ next }: any) {
    // const [gone] = useState(() => new Set());
    const [canTouch, changeTouch] = useState(false);
    const [_, domTarget] = useNextGesture(drag, canTouch);

    const [props, api] = useSprings(classNames.length, i => ({
        from: { opacity: 0, scale: 1, ...froms[i] },
        config: { mass: 4, tension: 250, friction: 40 }
    }))

    useEffect(() => {
        let index = -1;

        const animation = () => {
            index++;

            if (index != froms.length - 1) {
                setTimeout(animation, 400);
            } else {
                changeTouch(true);
            }

            api.start(i => {
                if (index !== i) return;

                return { x: 0, y: 0, scale: 1, opacity: 1 };
            });
        }

        setTimeout(animation, 200);
    }, []);

    function drag(mv: number, isEnd: boolean) {
        if (isEnd) {
            let ended = 0;
            let onRest = () => {
                ended++
                if (ended == froms.length) {
                    next();
                }
            }

            api.start(i => {
                let x = i < froms.length - 2 ? ((i + 1) % 3 == 0 ? -1 : 1) * 50 : 0;

                return { x, y: -150, opacity: 0, onRest };
            });
        } else {
            api.start(i => {
                let x = i < froms.length - 2 ? (((i + 1) % 3 == 0 ? 1 : -1) * 20 * (mv / 200)) : 0;

                return { x, y: 50 * (mv / 200) };
            });
        }
    }

    return <div className={styles.page} ref={domTarget}>
        {props.map(({ x, y, scale, opacity }, i) => (
            <animated.div key={i} className={classNames[i]} style={{ x, y, transform: scale.to(p => `scale(${p})`), opacity }}></animated.div>
        ))}
    </div>
}
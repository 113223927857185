import styles from './styles.module.css'
import { useSpring, useSprings, animated } from 'react-spring'
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useNextGesture } from '../../utils/useNextGesture';
import { useGesture } from '@use-gesture/react';

const classNames = [
    styles.p1,
    styles.p2,
    styles.p4,
    styles.p3
]

const froms = [
    { x: -10, y: -20, scale: 0.8 },
    { x: -10, y: -20, scale: 0.5 },
    { x: -10, y: -20, scale: 0.8 },
    { x: 10, y: -20, scale: 0.5 }
]

export default function Index({ next }: any) {
    const domTarget = useRef<HTMLDivElement>(null);
    const region = useRef({ width: 0, current: 0, left: 1, right: 2, other: 3, moveX: -1 });

    useGesture(
        {
            onDrag: ({ down, movement: [mx, my], velocity: [vx, velocity] }) => {
                const change = velocity > 0.2;
                const changeCard = vx > 0.2;

                region.current.moveX = Math.abs(mx) > Math.abs(my) ? 1 : 0;

                if (down) {
                    if (region.current.moveX === 0) {
                        const ychange = 50 * (my / 200)
                        api.start(i => {
                            return { x: 0, y: ychange };
                        });
                        oapi.start({ y: ychange });
                    } else {
                        api.start(i => reset(i, mx));
                    }
                } else {
                    if (region.current.moveX === 1 && changeCard) {
                        const now = region.current;
                        const { current, left, right, other } = now;

                        if (mx > 0) {
                            now.current = right;
                            now.left = current;
                            now.right = other;
                            now.other = left;
                        } else {
                            now.current = left;
                            now.left = other;
                            now.right = current;
                            now.other = right;
                        }

                        api.start(i => reset(i, 0));
                        return;
                    }

                    if (region.current.moveX === 0 && change) {
                        oapi.start({ y: -100, opacity: 0 });
                        end();
                        return;
                    }

                    region.current.moveX = -1;

                    api.start(i => reset(i));
                    oapi.start({ y: 0, opacity: 1 });
                }
            }
        },
        { target: domTarget, eventOptions: { passive: false } }
    );

    const [oprops, oapi] = useSpring(() => ({
        y: 0,
        opacity: 1
    }))

    const [props, api] = useSprings(classNames.length, i => ({
        from: { opacity: 0, ...froms[i], zIndex: 1, rotate: 0 },
        config: { mass: 2, tension: 250, friction: 40 }
    }))

    useLayoutEffect(() => {
        region.current.width = (domTarget.current?.offsetWidth || 800) * 0.6;

        api.start(i => reset(i));
    }, []);

    function reset(i: number, mx = 0) {
        const { width: maxWidth, current, left, right } = region.current;

        const immediate = (key: string) => key == "zIndex"

        if (i == current) {
            return { x: mx, y: 0, scale: 1, opacity: 1, rotate: 0, zIndex: 3, immediate };
        }

        if (i == left) {
            return { x: mx + maxWidth, y: 0, scale: 1, opacity: 1, rotate: 5, zIndex: 2, immediate };
        }

        if (i == right) {
            return { x: mx - maxWidth, y: 0, scale: 1, opacity: 1, rotate: -5, zIndex: 2, immediate };
        }

        return { scale: 1, x: 0, y: 0, opacity: 0, zIndex: 1, immediate };
    }

    function end() {
        let ended = 0;
        let onRest = () => {
            ended++
            if (ended == froms.length) {
                next();
            }
        }

        api.start(i => {
            return { x: 0, y: -150, opacity: 0, onRest };
        });
    }

    return (
        <div className={styles.page} ref={domTarget}>
            <animated.div className={styles.message} style={{ ...oprops }}>
            </animated.div>
            <animated.div className={styles.info} style={{ ...oprops }}>本科生在西湖的家<div>（正在建设中）</div></animated.div>
            {props.map(({ x, y, scale, opacity, rotate, zIndex }, i) => (
                console.log(region.current.other),
                <animated.div key={i} className={`${styles.card} ${classNames[i]}`} style={{ x, y, rotate, transform: scale.to(p => `scale(${p})`), opacity: opacity, zIndex: zIndex }}></animated.div>
            ))}
            <animated.div className={styles.iicon} style={{ ...oprops }}></animated.div>
        </div>
    );
}
import styles from './styles.module.css'
import { useSpring, useSprings, animated } from 'react-spring'
import { useEffect, useState } from 'react';
import { useNextGesture } from '../../utils/useNextGesture';
import { getUserType } from '../../utils/store';

const classNames = [
    styles.ibiaoti,
    styles.ixiaoxi1,
    styles.ixiaoxi2,
    styles.ixiahua,
    styles.ixiahua2
]

const froms = [
    { x: -10, y: -20, scale: 0.8 },
    { x: -10, y: -20, scale: 0.5 },
    { x: -10, y: -20, scale: 0.8 },
    { x: 10, y: 20, scale: 0.5 },
    { x: 10, y: 0, scale: 0.8 }
]

export default function Index({ next }: any) {
    const [canTouch, changeTouch] = useState(false);
    const [_, domTarget] = useNextGesture(drag, canTouch);

    const [props, api] = useSprings(classNames.length, i => ({
        from: { opacity: 0, ...froms[i] },
        config: { mass: 4, tension: 250, friction: 40 }
    }))

    const isMale = getUserType();

    useEffect(() => {
        let index = -1;

        const animation = () => {
            index++;

            if (index != froms.length - 1) {
                setTimeout(animation, 100);
            } else {
                changeTouch(true);
                console.log("end", index);
            }

            api.start(i => {
                if (index !== i) return;

                return { x: 0, y: 0, scale: 1, opacity: 1 };
            });
        }

        setTimeout(animation, 200);
    }, []);

    function drag(mv: number, isEnd: boolean) {
        if (isEnd) {
            let ended = 0;
            let onRest = () => {
                ended++
                if (ended == froms.length) {
                    next();
                }
            }

            api.start(i => {
                let x = i < froms.length - 2 ? ((i + 1) % 3 == 0 ? 1 : -1) * 50 : 0;

                return { x, y: -150, opacity: 0, onRest };
            });
        } else {
            api.start(i => {
                let x = i < froms.length - 2 ? (((i + 1) % 3 == 0 ? -1 : 1) * 20 * (mv / 200)) : 0;

                return { x, y: 50 * (mv / 200) };
            });
        }
    }

    return <div className={styles.page} ref={domTarget}>
        <div className={styles.ibeijing} />
        <div className={styles.iyun1} />
        <div className={styles.iyun2} />
        <div className={styles.iyun3} />
        <div className={styles.iyun4} />
        <div className={styles.iyun5} />
        {props.map(({ x, y, scale, opacity }, i) => (
            <animated.div key={i} className={`${classNames[i]} ${(i == 1 && isMale) || (i == 2 && !isMale) ? styles.male : ""}`} style={{ x, y, transform: scale.to(p => `scale(${p})`), opacity }} />
        ))}
        {/* <div className={styles.ibiaoti} /> i == 3 ? (classNames[i] + (isMale ? " " + styles.male : "")) : (i == 4 ? (classNames[i] + (isMale ? "" : " " + styles.male)) :
        <div className={styles.ixiaoxi1} />
        <div className={styles.ixiaoxi2} />
        <div className={styles.ixiahua} />
        <div className={styles.ixiahua2} /> */}
    </div>
}
import styles from './styles.module.css'
import { useSpring, useSprings, animated } from 'react-spring'
import React, { useState } from 'react';
import { useGesture } from '@use-gesture/react';
import { getUserName, selectUserType } from '../../utils/store';

async function wxLink(appId: string) {
    const url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appId}&redirect_uri=${encodeURIComponent("https://westlake.q-huan.link/api/wxlogin")}&response_type=code&scope=snsapi_userinfo&state=westlake#wechat_redirect`;
    window.location.href = url;
}

let isToLogin = false;

export default function Index({ next }: any) {
    const [selected, changeSelect] = useState(false);

    const [{ sy }, api] = useSpring(() => ({
        to: { sy: 1 },
        from: { sy: 0.9 }
    }));

    const bind = useGesture({
        onPointerDown: ({ args: [isOne] }) => change(isOne),
        onDrag: ({ down, movement: [mx, my], velocity: [_, velocity] }) => {
            console.log(down, mx);
        }
    })

    return <div className={styles.page}>
        <animated.div className={styles.idengguang} style={{ transform: sy.to(p => `scaleY(${p})`), x: !selected ? "5vw" : "50vw" }} />
        <div className={styles.iicon} onClick={() => enter()} />
        <div className={styles.iwenzi} />
        <div className={styles.ixiaoxi1} {...bind(false)} />
        <div className={styles.ixiaoxi2} {...bind(true)} />
    </div>

    function change(isMale: boolean) {
        changeSelect(isMale);
        selectUserType(isMale);
        api.start({
            to: { sy: 1 },
            from: { sy: 0.9 }
        });
    }

    async function enter() {
        if (!getUserName()) {
            let res = await fetch("/api/wxInfo");
            let data = await res.json();

            wxLink(data.APPID);
        } else {
            next();
        }
    }
}